<template>
  <div></div>
</template>

<script>
export default {
  name:'',
  data(){
    return {
      code: ''
    }
  },
  mounted(){
    this.code = this.$route.query.code;
    if(this.code){
      this.getLogin();
    }
  },
  methods:{
    async getLogin(){
      var params = {
        code: this.code
      }
      var res = await this.$api.post('/api/thirdBind/wxCodeLogin', params);
      if(res.code == 0){
        var use = await this.$api.get('/api/member_center/index');
        if (use.code == 0) {
          this.$message.success('登录成功');
          var userInfo = use.data;
          setItem('user', userInfo);
          this.$store.dispatch('setUserModal');
          this.$router.push('/');
        } else {
          // 顺便判断一下是否登录过期
          this.user = '';
          localStorage.removeItem('user');
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
